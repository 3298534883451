@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  /* background-color: #ffffff; */
  font-family: "Roboto", sans-serif;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

/* ********************Font Sizes*********************** */

.fs-20 {
  font-size: 1.1vw;
}

.fs-18 {
  font-size: 1vw;
}

@media screen and (max-width: 767px) {
  .fs-20 {
    font-size: 4.5vw;
  }
}

/* ********************Font weight*********************** */
.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

@media screen and (min-width: 768px) {
  .mobile {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .desktop {
    display: none !important;
  }
}

/* .app-layout.boarding-form-div {
  padding-left: 0 !important;
} */
/* ************************ boarding form country code input and date picker********************** */

@media screen and (min-width: 768px) {
  .react-tel-input .form-control {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .react-tel-input .form-control {
    width: 100% !important;
  }

  .boarding-form-date-picker {
    width: 100%;
  }
}

/* ***********************vertical tabs pop over of follow and notes********************* */
/* #follow-up-popover {
  width: 25vw;
} */

/* *****************dashboard px div*********************** */

@media screen and (max-width: 767px) {
  .dashboard-div-mob {
    padding-left: 12px;
    padding-right: 12px;
  }
}

/* ************************doc upload******************** */

/* @media screen and (min-width: 768px) {
  .ant-upload.ant-upload-drag {
    width: 210% !important;
  }
} */
.doc-upload.ant-upload.ant-upload-drag {
  min-height: 123px;
}

/* **************************doc uploaded div****************** */

/* .ant-upload-list-item-info {
  border: black solid 2px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
} */
.ant-upload-list-item {
  border: black solid 2px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  border-radius: 10px;
}

/* ********************new campaign img******************* */

.new-campaign-img {
  /* background-image: url("/public/img/New_campaign.png"); */
}

@media screen and (max-width: 767px) {

  .message-content-div,
  .delete-button {
    margin-top: 15px;
  }
}

/* ***************************Search on response list*********************** */
.response-List-search.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
  display: none;
}

#response-List-search-id.response-List-search.ant-input-group-addon:first-child,
.ant-input-group-addon:first-child .ant-select .ant-select-selector,
.ant-input-group>.ant-input:first-child,
.ant-input-group>.ant-input:first-child .ant-select .ant-select-selector {
  border-radius: 5px;
}

/* *************************outcome survey dtl btn*********************** */
@media screen and (max-width: 767px) {
  .survey-dlt-btn {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
}

/* ******************* antd table pagination************************* */
.ant-table-pagination {
  justify-content: center !important;
}

p {
  margin-bottom: 0 !important;
}

/* ************************patient details button flex div and main card************* */

@media screen and (max-width: 767px) {
  .patient-details-button-div {
    display: flex !important;
  }

  .btn-mssgs {
    width: 50%;
    /* padding-left: 3%;
    padding-right: 5%; */
  }
}

@media screen and (max-width: 767px) {
  .patient-detail-main-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* justify-content: space-between; */
  }
}

/* ***************************communication logs mobile log tab********************************** */

.mobile-communication-tab-button .ant-card-body {
  padding: 0;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

/* ***********************input border color and focus ************************88 */
.ant-input:focus,
.ant-input-focused,
.ant-input:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover,
.ant-picker-input:focus,
.ant-picker-input-focused,
.ant-picker-input:hover,
.ant-input-search:focus,
.ant-input-search-focused,
.ant-input-search:hover {
  border-color: #000000;
  /* Change this color to match your input's border color */
  box-shadow: none;
  /* Remove any box shadow */
}

.ant-input-search:focus,
.ant-input-search-focused,
.ant-input-search:hover {
  border-color: #000000;
  /* Change this color to match your input's border color */
  box-shadow: none;
  /* Remove any box shadow */
}

.ant-picker-input:focus,
.ant-picker-input-focused,
.ant-picker-input:hover {
  border-color: #000000;
  /* Change this color to match your input's border color */
  box-shadow: none;
  /* Remove any box shadow */
}

.ant-select:focus,
.ant-select-focused,
.ant-select:hover,
.ant-select-affix-wrapper:focus,
.ant-select-affix-wrapper-focused,
.ant-select-affix-wrapper:hover,
.ant-picker-select:focus,
.ant-picker-select-focused,
.ant-picker-select:hover,
.ant-select-search:focus,
.ant-select-search-focused,
.ant-select-search:hover {
  border-color: #000000;
  /* Change this color to match your select's border color */
  box-shadow: none;
  /* Remove any box shadow */
}

.ant-select-search:focus,
.ant-select-search-focused,
.ant-select-search:hover {
  border-color: #000000;
  /* Change this color to match your select's border color */
  box-shadow: none;
  /* Remove any box shadow */
}

.ant-picker-select:focus,
.ant-picker-select-focused,
.ant-picker-select:hover {
  border-color: #000000;
  /* Change this color to match your select's border color */
  box-shadow: none;
  /* Remove any box shadow */
}

/* Add this CSS to your stylesheet */
.ant-select:hover .ant-select-selector {
  border-color: black !important;
}


.ant-table {
  border: solid #e6e6e6 0.4px;
}

/* ***************form item margin bottom default*********************** */
.ant-form-item {
  margin-bottom: 9px !important;
}